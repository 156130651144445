import { Component, OnInit } from '@angular/core';
import { AppData } from '../../services/appdata.service';
import { API } from '../../services/api.service';
import { Global } from '../../services/global.service';
import { Projects } from '../../services/projects.service';
import { ValidationService } from '../../services/validation.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Config } from 'src/app/models/env-vars.model';

import * as moment from 'moment';

@Component({
    selector: 'app-review-selection',
    templateUrl: './review-selection.component.html',
    styleUrls: ['./review-selection.component.css']
})
export class ReviewSelectionComponent implements OnInit {
    config = Config;
    base_score_data_tree_show: boolean = true;
    need_redirect_guidance: boolean = true;
    showThisLoader: boolean = false;
    recompute_loading: boolean = true;
    recompute_data_lock: boolean = false;
    base_score_city_files: any = [];
    base_status: any = 'Attempted';
    alert_of: any = [];
    selection_review_data: any = {};
    team_members: any = [];
    acp_warning_message: string = '';
    canada_payment: boolean = false;
    area_in_sqm: any = null;

    registration_status: string = 'not_applicable';
    registration_loading: boolean = false;
    isBuildingRecert: boolean = false;
    DI_heading_name: string = 'Performance categories summary';

    constructor(
        public global: Global,
        private api: API,
        public appData: AppData,
        private projects: Projects,
        private alertService: alertService,
        private validator: ValidationService
    ) { }

    validateRedirectLink() {
        if ((this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') && (this.selection_review_data.review_type == 'Certification' || this.selection_review_data.review_type == 'Pre-Certification')) {
            this.need_redirect_guidance = false;
        }
        else if ((this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') && this.selection_review_data.review_type == 'LEED Certification' && this.appData.get('buildingData').score_code_version.name < 3) {
            this.need_redirect_guidance = false;
        }
    };

    formatNull(score: any) {
        if (score) {
            return score;
        }
        else {
            return 0;
        }
    };

    IsReviewAllowed() {
        if ((!this.selection_review_data.ready_for_review || this.appData.get('buildingData').test_project) && !this.config.maintenance_banner) {
            $("#AS_program_filters").show();
        }
    };

    triggerEndDate() {
        (<any>$('#end_review_date')).fdatepicker('show');
    };

    IsRegistrationPaymentCompleted() {
        var is_LDP = false;
        var is_lov2 = false;
        var registration_order_found = false;
        var registration_order_cleared = false;

        for (var i = 0; i < this.selection_review_data.payment_details.ItOrders.length; i++) {
            var order = this.selection_review_data.payment_details.ItOrders[i];
            if ((!('receipt_url' in order)) && (order.OrderType == "SUBSCRIPTION" || order.OrderType == "SUBSPAIDFULL" || order.OrderType == "LEASE" || order.OrderType == "PLAQUE" || order.OrderType == "PAIDFULL")) {
                is_LDP = true;
            }
            if ((String(this.appData.get('leed_id'))).length == 8) {
                is_lov2 = true;
            }
            if (order.OrderType == "REGISTRATION") {
                registration_order_found = true;
            }
            if (order.OrderType == "REGISTRATION" && !((order.OrderStatus.indexOf("Pending") > -1) || order.OrderStatus == 'Auto Cancelled')) {
                registration_order_cleared = true;
            }
        }

        if (is_LDP) {
            return { "status": true, "message": "" };
        }
        else if (is_lov2) {
            return { "status": true, "message": "" };
        }
        else if (registration_order_found && registration_order_cleared) {
            return { "status": true, "message": "" };
        }
        else if (registration_order_found && !registration_order_cleared) {
            return { "status": false, "message": "" };
        }
        else {
            return { "status": false, "message": "not found" };
        }
    };

    IsReviewPaymentCompleted() {
        if (this.selection_review_data.review_history.review_payment != undefined) {
            var review_payment = this.selection_review_data.review_history.review_payment[0];
            if (review_payment.status == 'completed' || review_payment.bypass_payment) {
                return true;
            }
            return false;
        }
        else {
            return true;
        }
    };

    IsProjectUnderReview() {
        var rt = false;
        if (this.selection_review_data.review_history.review_data != undefined) {
            var review_data = this.selection_review_data.review_history.review_data;
            for (var i = 0; i < review_data.length; i++) {
                if (review_data[i].status == 'under_review') {
                    rt = true;
                }
            }
        }
        return rt;
    };

    IsUnitTypeCorrect() {
        var rt = true;
        if (this.appData.get('buildingData').unitType == null || (this.appData.get('buildingData').unitType.toUpperCase() != 'SI' && this.appData.get('buildingData').unitType.toUpperCase() != 'IP')) {
            rt = false;
        }
        return rt;
    };

    IsAreaNullOrZero() {
        var rt = false;
        if (this.appData.get('buildingData').gross_area == null || this.appData.get('buildingData').gross_area == 0) {
            rt = true;
        }
        return rt;
    };

    IsOperatingHoursNullOrZero() {
        var rt = false;
        if (this.appData.get('buildingData').operating_hours == null || this.appData.get('buildingData').operating_hours == 0) {
            rt = true;
        }
        return rt;
    };

    IsOccupancyNullOrZero() {
        var rt = false;
        if (this.appData.get('buildingData').occupancy == null || this.appData.get('buildingData').occupancy == 0) {
            rt = true;
        }
        return rt;
    };

    getCertificationName() {
        this.registration_status = 'not_applicable';
        this.isBuildingRecert = false;
        this.selection_review_data.cert_rating_system = this.appData.get('project_rating_system');
        if (this.selection_review_data.need_review_type_drp) {
            if (this.selection_review_data.review_type == 'Certification') {
                if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') {
                    this.selection_review_data.cert_rating_system = "LEED v4 O+M certification";
                }
                else if (this.appData.get('buildingData').project_type == 'transit') {
                    this.selection_review_data.cert_rating_system = "LEED v4 O+M: Transit certification"
                }
                else if (this.appData.get('buildingData').project_type == 'city') {
                    this.selection_review_data.cert_rating_system = "LEED for Cities certification"
                }
                else if (this.appData.get('buildingData').project_type == 'community') {
                    this.selection_review_data.cert_rating_system = "LEED for Communities certification"
                }
            }
            else if (this.selection_review_data.review_type == 'Pre-Certification') {
                if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') {
                    this.selection_review_data.cert_rating_system = "LEED v4 O+M Pre-Certification";
                }
                else if (this.appData.get('buildingData').project_type == 'transit') {
                    this.selection_review_data.cert_rating_system = "LEED v4 O+M: Transit Pre-Certification"
                }
                else if (this.appData.get('buildingData').project_type == 'city') {
                    this.selection_review_data.cert_rating_system = "LEED for Cities Pre-Certification"
                }
                else if (this.appData.get('buildingData').project_type == 'community') {
                    this.selection_review_data.cert_rating_system = "LEED for Communities Pre-Certification"
                }
            }

        }
        else {
            if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') {
                this.isBuildingRecert = true;
                this.getCerRegistrationDetails();
            }
            else if (this.appData.get('buildingData').project_type == 'transit') {
                this.selection_review_data.cert_rating_system = "LEED v4 O+M: Transit recertification"
            }
            else if (this.appData.get('buildingData').project_type == 'city') {
                this.selection_review_data.cert_rating_system = "LEED for Cities recertification"
            }
            else if (this.appData.get('buildingData').project_type == 'community') {
                this.selection_review_data.cert_rating_system = "LEED for Communities recertification"
            }
        }

    };

    updateCertification() {
        this.getCertificationName();
        this.selection_review_data.only_review_button = "Submit for review";
    };

    removeItemOnce(arr: any, value: any) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };

    IsReviewOnly() {
        var rt = false;
        this.selection_review_data.only_review_button = "Submit for review";
        if (this.selection_review_data.review_history.review_payment != undefined) {
            var review_payment = this.selection_review_data.review_history.review_payment[0];
            if (review_payment.bypass_payment) {
                rt = true;
            }
            else if (review_payment.material_desc == 'LEED Dynamic Plaque Subscription' && this.IsLDPSubscriptionActive()) {
                var subscription_started_on = new Date(this.global.formatDateT(review_payment.payment_date));
                var ldp_order = [];
                var ldp_cleared_order = [];
                for (var i = 0; i < this.selection_review_data.payment_details.ItOrders.length; i++) {
                    var order = this.selection_review_data.payment_details.ItOrders[i];
                    if ((!('receipt_url' in order)) && (order.OrderType == "SUBSCRIPTION" || order.OrderType == "SUBSPAIDFULL" || order.OrderType == "LEASE" || order.OrderType == "PLAQUE" || order.OrderType == "PAIDFULL")) {
                        var order_date = new Date(this.global.formatDateT(order.Erdat));
                        if (order_date >= subscription_started_on && order.OrderStatus != 'Payment Cancelled') {
                            ldp_order.push(order.Vbeln);
                            if (order.OrderStatus == 'Payment Cleared') {
                                ldp_cleared_order.push(order.Vbeln);
                            }
                        }
                    }
                }
                if (this.selection_review_data.review_history.recurring_payment != undefined && this.selection_review_data.review_history.recurring_payment.length > 0) {
                    var recurring_payment = this.selection_review_data.review_history.recurring_payment[0];
                    if (review_payment.order_number != recurring_payment.order_number) {
                        ldp_order = this.removeItemOnce(ldp_order, recurring_payment.order_number)
                        ldp_cleared_order = this.removeItemOnce(ldp_cleared_order, recurring_payment.order_number)
                    }
                }
                if (ldp_order.length == ldp_cleared_order.length) {
                    rt = true;
                }
            }
        }
        this.selection_review_data.only_review = rt;
        return rt;
    };

    IsLDPSubscriptionActive() {
        var rt = false;
        if (this.selection_review_data.review_history.review_payment != undefined) {
            var review_payment = this.selection_review_data.review_history.review_payment[0];
            var paid_on = new Date(this.global.formatDateT(review_payment.payment_date));
            var paid_till = new Date(this.global.formatDateT(review_payment.payment_date));
            paid_till.setFullYear(paid_till.getFullYear() + review_payment.certification_payterm);
            if (new Date() >= paid_on && new Date() <= paid_till && review_payment.material_desc == 'LEED Dynamic Plaque Subscription') {
                rt = true;
            }
        }
        return rt;
    };

    getReviewGrossArea() {
        if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'transit' || this.appData.get('buildingData').project_type == 'school') {
            if (this.appData.get('buildingData').unitType == "SI") {
                var gross_area = this.appData.get('buildingData').gross_area * 10.7639;
                return parseFloat((parseFloat(gross_area.toString())).toFixed(3))
            }
        }
        else if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community') {
            if (this.appData.get('buildingData').unitType == "IP") {
                var gross_area = this.appData.get('buildingData').gross_area * 2.58999
                return parseFloat((parseFloat(gross_area.toString())).toFixed(3))
            }
        }
        return parseFloat((parseFloat(this.appData.get('buildingData').gross_area)).toFixed(3))
    };

    alreadyPrePaid() {
        if (this.selection_review_data.review_history.review_payment != undefined) {
            var review_payment = this.selection_review_data.review_history.review_payment[0];
            return review_payment.bypass_payment;
        }
        else {
            return false;
        }
    };

    IsAreaChanged() {
        if (this.selection_review_data.review_history.review_payment != undefined) {
            var review_payment = this.selection_review_data.review_history.review_payment[0];
            if (review_payment.bypass_area) {
                return false;
            }
            else if (this.getReviewGrossArea() > review_payment.quantity) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };

    createTicket(review_obj: any) {
        this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/review/ID:' + review_obj.id + '/', { "notified_change": true }).subscribe(
            data => {
            }, error => {
            }
        );
    };

    validationForContinue() {
        this.selection_review_data.ready_for_review = true;
        this.selection_review_data.ready_for_review_txt = "";
        this.selection_review_data.max_limit_block = false;
        this.selection_review_data.hide_total = false;
        this.selection_review_data.manage_billing_flag = false;
        this.selection_review_data.review_area_changed = false;

        var registration_status = this.IsRegistrationPaymentCompleted();
        var already_prepaid = this.alreadyPrePaid();
        if (this.IsProjectUnderReview()) {
            this.selection_review_data.ready_for_review = false;
            this.selection_review_data.ready_for_review_txt = "Project is still under review";
        }
        else if (!registration_status.status) {
            this.selection_review_data.ready_for_review = false;
            if (registration_status.message == "not found") {
                this.selection_review_data.ready_for_review_txt = "Registration payment is missing";
            }
            else {
                this.selection_review_data.ready_for_review_txt = "Registration payment is still under process";
            }
        }
        else if (!this.IsReviewPaymentCompleted()) {
            this.selection_review_data.ready_for_review = false;
            this.selection_review_data.manage_billing_flag = true;
            this.selection_review_data.ready_for_review_txt = 'Please complete your review payment in order to proceed';
        }
        else if (!this.IsUnitTypeCorrect()) {
            this.selection_review_data.ready_for_review = false;
            this.selection_review_data.hide_total = true;
            if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community') {
                this.selection_review_data.ready_for_review_txt = "Please update unit type from Project Settings inside Data Input section";
            }
            else {
                this.selection_review_data.ready_for_review_txt = "Please update unit type from Project Settings inside Meters & Surveys section";
            }
        }
        else if (this.IsAreaNullOrZero()) {
            this.selection_review_data.ready_for_review = false;
            this.selection_review_data.hide_total = true;
            if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community') {
                this.selection_review_data.ready_for_review_txt = "Please update project area from Project Settings inside Data Input section";
            }
            else {
                this.selection_review_data.ready_for_review_txt = "Please update project area from Project Settings inside Meters & Surveys section";
            }
        }
        else if (already_prepaid && this.IsAreaChanged()) {
            this.selection_review_data.ready_for_review = false;
            this.selection_review_data.manage_billing_flag = true;
            this.selection_review_data.review_area_changed = true;
            if (this.selection_review_data.review_history.review_payment != undefined) {
                var review_payment = this.selection_review_data.review_history.review_payment[0];
                if (!review_payment.notified_change) {
                    this.createTicket(review_payment);
                }
            }
        }
        else if (!already_prepaid) {
            if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community') {
                if (this.appData.get('buildingData').unitType.toUpperCase() == 'SI' && this.appData.get('buildingData').gross_area > 50000 && this.selection_review_data.review_type != "Pre-Certification") {
                    this.selection_review_data.max_limit_block = true;
                    this.selection_review_data.ready_for_review = false;
                }
                else if (this.appData.get('buildingData').unitType.toUpperCase() == 'IP' && this.appData.get('buildingData').gross_area > 19305 && this.selection_review_data.review_type != "Pre-Certification") {
                    this.selection_review_data.max_limit_block = true;
                    this.selection_review_data.ready_for_review = false;
                }
            }
            else if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school' || this.appData.get('buildingData').project_type == 'transit') {
                if (this.appData.get('buildingData').unitType.toUpperCase() == 'SI' && this.appData.get('buildingData').gross_area > 1904514 && this.selection_review_data.review_type != "Pre-Certification" && !this.IsLDPSubscriptionActive()) {
                    this.selection_review_data.max_limit_block = true;
                    this.selection_review_data.ready_for_review = false;
                }
                else if (this.appData.get('buildingData').unitType.toUpperCase() == 'IP' && this.appData.get('buildingData').gross_area > 20499999 && this.selection_review_data.review_type != "Pre-Certification" && !this.IsLDPSubscriptionActive()) {
                    this.selection_review_data.max_limit_block = true;
                    this.selection_review_data.ready_for_review = false;
                }
            }
        }
    };

    revertBackDate(date: any) {
        var nowTemp = new Date();
        nowTemp.setDate(nowTemp.getDate() - 1);
        (<any>$('#end_review_date')).fdatepicker({
            initialDate: date,
            format: 'mm/dd/yyyy',
            onRender: function (date: any) {
                return date.valueOf() > nowTemp.valueOf() ? 'disabled' : '';
            }
        });
        this.selection_review_data.review_end_date = this.selection_review_data.last_selected_period;

        this.selection_review_data.date_range = "You have selected " + moment(this.selection_review_data.review_end_date, "MM/DD/YYYY").subtract(1, 'year').add(1, 'day').format("MMMM DD, YYYY") + " to " + moment(this.selection_review_data.review_end_date, "MM/DD/YYYY").format("MMMM DD, YYYY") + " as the reporting period.";
    };

    changePerformancePeriod(reset_date: any) {
        this.recompute_data_lock = true;
        this.selection_review_data.project_settings = "";
        this.selection_review_data.performanceData = {};
        this.selection_review_data.score_obj = {};

        var selected_date = this.selection_review_data.review_end_date.split('/');

        if (reset_date) {
            var nowTemp = new Date();
            nowTemp.setDate(nowTemp.getDate() - 1);
            selected_date = [('0' + String(nowTemp.getMonth() + 1)).slice(-2), ('0' + String(nowTemp.getDate())).slice(-2), String(nowTemp.getFullYear())];

            this.selection_review_data.review_end_date = ('0' + String((nowTemp).getMonth() + 1)).slice(-2) + '/' + ('0' + String((nowTemp).getDate())).slice(-2) + '/' + String((nowTemp).getFullYear());

            (<any>$('#end_review_date')).fdatepicker({
                initialDate: this.selection_review_data.review_end_date,
                format: 'mm/dd/yyyy',
                onRender: function (date: any) {
                    return date.valueOf() > nowTemp.valueOf() ? 'disabled' : '';
                }
            });
        }

        var end_date = selected_date[2] + '-' + selected_date[0] + '-' + selected_date[1];
        var url = '/assets/LEED:' + this.appData.get('leed_id') + '/scores/recompute/?at=' + end_date;
        var url_datacoverage = '/assets/LEED:' + this.appData.get('leed_id') + '/datacoverage/?at=' + end_date;
        var url_project_settings = '/assets/LEED:' + this.appData.get('leed_id') + '/project-settings/?date=' + end_date;

        this.selection_review_data.date_range = "You have selected " + moment(this.selection_review_data.review_end_date, "MM/DD/YYYY").subtract(1, 'year').add(1, 'day').format("MMMM DD, YYYY") + " to " + moment(this.selection_review_data.review_end_date, "MM/DD/YYYY").format("MMMM DD, YYYY") + " as the reporting period.";

        var url_arr = [url, url_datacoverage];
        if (this.appData.get('buildingData').score_code_version.name >= 3 && (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school')) {
            url_arr.push(url_project_settings);
        }

        this.api.async(url_arr).subscribe(data => {
            var score_data = data[0];
            var datacoverage = data[1];
            if (score_data.effective_at == undefined) {
                this.recompute_data_lock = false;
                this.revertBackDate(this.selection_review_data.last_selected_period);
                if (score_data.indexOf('missing for this project') > -1) {
                    this.alertService.alert('error', score_data, 5);
                }
                else {
                    this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
                }
            }
            else {
                if (this.appData.get('buildingData').score_code_version.name >= 3 && (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school')) {
                    this.selection_review_data.project_settings = data[2].text_message;
                }

                this.selection_review_data.last_selected_period = this.selection_review_data.review_end_date;
                this.recompute_data_lock = false;
                this.selection_review_data.performanceData = score_data.scores;
                this.selection_review_data.score_obj = score_data;

                if (this.selection_review_data.performanceData == undefined) {
                    this.selection_review_data.performanceData = { "energy": 0, "water": 0, "waste": 0, "human_experience": 0, "transport": 0, "base": 0 };
                }

                var score_count = 0;
                var total_base_points = 0;

                if (this.appData.get('buildingData').base_score) {
                    total_base_points = parseInt((Math.round(this.appData.get('buildingData').base_score / 10).toString()));
                }

                if (total_base_points >= 10) {
                    total_base_points = 10;
                }

                score_count = total_base_points;
                if (this.selection_review_data.performanceData) {
                    score_count += this.selection_review_data.performanceData.energy + this.selection_review_data.performanceData.water + this.selection_review_data.performanceData.waste + this.selection_review_data.performanceData.transport + this.selection_review_data.performanceData.human_experience;
                }

                this.selection_review_data.target_score = score_count;
                this.selection_review_data.target_score_obj = this.selection_review_data.performanceData;
                this.selection_review_data.target_score_base = total_base_points;

                if (40 <= score_count && score_count <= 49) {
                    this.selection_review_data.target_level = "Certified";
                } else if (50 <= score_count && score_count <= 59) {
                    this.selection_review_data.target_level = "Silver";
                } else if (60 <= score_count && score_count <= 79) {
                    this.selection_review_data.target_level = "Gold";
                } else if (80 <= score_count) {
                    this.selection_review_data.target_level = "Platinum";
                }
                else {
                    this.selection_review_data.target_level = "Not applicable";
                }

                this.selection_review_data.cert_level = this.selection_review_data.target_level;
            }

            var actions: any = this.selection_review_data.complete_all_actions;
            for (var i = 0; i < actions.length; i++) {
                if (actions[i].CreditShortId == 'PF901') {
                    actions[i]["score"] = this.selection_review_data.performanceData["energy"];
                    actions[i]["datacoverage"] = datacoverage["energy"];
                    actions[i]["document_count"] = datacoverage["energy"]["documents"];
                }
                else if (actions[i].CreditShortId == 'PF902') {
                    actions[i]["score"] = this.selection_review_data.performanceData["water"];
                    actions[i]["datacoverage"] = datacoverage["water"];
                    actions[i]["document_count"] = datacoverage["water"]["documents"];
                }
                else if (actions[i].CreditShortId == 'PF903') {
                    actions[i]["score"] = this.selection_review_data.performanceData["waste"];
                    actions[i]["datacoverage"] = datacoverage["waste"];
                    actions[i]["document_count"] = datacoverage["waste"]["documents"];
                }
                else if (actions[i].CreditShortId == 'PF904') {
                    actions[i]["score"] = this.selection_review_data.performanceData["transport"];
                    actions[i]["datacoverage"] = datacoverage["transportation"];
                    actions[i]["document_count"] = datacoverage["transportation"]["documents"];
                }
                else if (actions[i].CreditShortId == 'PF905') {
                    actions[i]["score"] = this.selection_review_data.performanceData["human_experience"];
                    actions[i]["datacoverage"] = datacoverage["humanexperience"];
                    actions[i]["document_count"] = datacoverage["humanexperience"]["documents"];
                }
            }
            this.selection_review_data.selected_review_end_date = true;
            this.refreshSelectedDI();
        }, error => {
            this.recompute_data_lock = false;
            this.selection_review_data.selected_review_end_date = true;
            this.revertBackDate(this.selection_review_data.last_selected_period);
            this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
            this.refreshSelectedDI();
        });
    };

    //Filter Base Points
    only_base(obj: any) {
        return ((obj.Mandatory != 'X') && (obj.CreditcategoryDescrption != 'Performance Category') && (obj.CreditcategoryDescrption != 'Performance'));
    };

    //Filter Data Input
    only_PF(obj: any) {
        return ((obj.CreditShortId == 'PF901') || (obj.CreditShortId == 'PF902') || (obj.CreditShortId == 'PF903') || (obj.CreditShortId == 'PF904') || (obj.CreditShortId == 'PF905') || (obj.CreditShortId == 'PF906'));
    };

    updateCheckedCredits(CreditShortId: any) {
        var index = this.selection_review_data.checked_credits.indexOf(CreditShortId);
        if (index > -1) {
            this.selection_review_data.checked_credits.splice(index, 1);
        }
        else {
            this.selection_review_data.checked_credits.push(CreditShortId);
        }
        if (this.isBuildingRecert){
            this.selection_review_data.target_score = this.selection_review_data.target_score_base;
            for (let index = 0; index < this.selection_review_data.checked_credits.length; index++) {
                const credit = this.selection_review_data.checked_credits[index];
                if (credit == 'PF901'){
                    this.selection_review_data.target_score += this.selection_review_data.performanceData.energy;
                }
                else if (credit == 'PF902'){
                    this.selection_review_data.target_score += this.selection_review_data.performanceData.water;
                }
                else if (credit == 'PF903'){
                    this.selection_review_data.target_score += this.selection_review_data.performanceData.waste;
                }
                else if (credit == 'PF904'){
                    this.selection_review_data.target_score += this.selection_review_data.performanceData.transport;
                }
                else if (credit == 'PF905'){
                    this.selection_review_data.target_score += this.selection_review_data.performanceData.human_experience;
                }
            }
        }
    };

    SummaryCheck() {
        this.selection_review_data.base_attempted = 0;
        this.selection_review_data.all_reviewed_actions = "";
        this.selection_review_data.all_actions_name = "";
        this.selection_review_data.prereqs_actions_name = "";
        this.selection_review_data.base_actions_name = "";
        this.selection_review_data.DI_actions_name = "";

        for (var i = 0; i < this.selection_review_data.complete_all_actions.length; i++) {
            if ((this.selection_review_data.review_type == 'Pre-Certification' || this.selection_review_data.review_type == 'Certification') && !this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                if (this.selection_review_data.complete_all_actions[i].Mandatory == 'X') {
                    this.selection_review_data.all_reviewed_actions += this.selection_review_data.complete_all_actions[i].CreditId;
                    this.selection_review_data.all_reviewed_actions += ',';
                    this.selection_review_data.all_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                    this.selection_review_data.all_actions_name += ',';
                    this.selection_review_data.prereqs_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                    this.selection_review_data.prereqs_actions_name += ',';
                }
                else if (this.only_base(this.selection_review_data.complete_all_actions[i])) {
                    if (this.selection_review_data.complete_all_actions[i].CreditStatus == "Ready for Review" && (this.selection_review_data.checked_credits.indexOf(this.selection_review_data.complete_all_actions[i].CreditShortId) > -1)) {
                        this.selection_review_data.all_reviewed_actions += this.selection_review_data.complete_all_actions[i].CreditId;
                        this.selection_review_data.all_reviewed_actions += ',';
                        this.selection_review_data.all_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                        this.selection_review_data.all_actions_name += ',';
                        this.selection_review_data.base_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                        this.selection_review_data.base_actions_name += ',';
                        var temp_base_point = this.selection_review_data.complete_all_actions[i].PointsAvailable;
                        if (temp_base_point.split("-")[1] != undefined) {
                            temp_base_point = temp_base_point.split("-")[1];
                        }
                        this.selection_review_data.base_attempted += parseInt(temp_base_point);
                    }
                }
                else if (this.only_PF(this.selection_review_data.complete_all_actions[i])) {
                    this.selection_review_data.all_reviewed_actions += this.selection_review_data.complete_all_actions[i].CreditShortId;
                    this.selection_review_data.all_reviewed_actions += ',';
                    this.selection_review_data.all_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                    this.selection_review_data.all_actions_name += ',';
                    this.selection_review_data.DI_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                    this.selection_review_data.DI_actions_name += ',';
                }
            }
            else {
                if (this.only_PF(this.selection_review_data.complete_all_actions[i])) {
                    if (this.isBuildingRecert){
                        if (this.selection_review_data.checked_credits.indexOf(this.selection_review_data.complete_all_actions[i].CreditShortId) > -1){
                            this.selection_review_data.all_reviewed_actions += this.selection_review_data.complete_all_actions[i].CreditShortId;
                            this.selection_review_data.all_reviewed_actions += ',';
                            this.selection_review_data.all_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                            this.selection_review_data.all_actions_name += ',';
                            this.selection_review_data.DI_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                            this.selection_review_data.DI_actions_name += ',';
                        }
                    }
                    else{
                        this.selection_review_data.all_reviewed_actions += this.selection_review_data.complete_all_actions[i].CreditShortId;
                        this.selection_review_data.all_reviewed_actions += ',';
                        this.selection_review_data.all_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                        this.selection_review_data.all_actions_name += ',';
                        this.selection_review_data.DI_actions_name += this.selection_review_data.complete_all_actions[i].CreditDescription;
                        this.selection_review_data.DI_actions_name += ',';
                    }
                }
            }
        }

        if (this.selection_review_data.base_attempted > 10) {
            this.selection_review_data.base_attempted = 10;
        }

        if (this.selection_review_data.all_reviewed_actions.length) {
            this.selection_review_data.all_reviewed_actions = this.selection_review_data.all_reviewed_actions.slice(0, -1);
        }

        if (this.selection_review_data.all_actions_name.length) {
            this.selection_review_data.all_actions_name = this.selection_review_data.all_actions_name.slice(0, -1);
        }

        if (this.selection_review_data.prereqs_actions_name.length) {
            this.selection_review_data.prereqs_actions_name = this.selection_review_data.prereqs_actions_name.slice(0, -1);
        }

        if (this.selection_review_data.base_actions_name.length) {
            this.selection_review_data.base_actions_name = this.selection_review_data.base_actions_name.slice(0, -1);
        }

        if (this.selection_review_data.DI_actions_name.length) {
            this.selection_review_data.DI_actions_name = this.selection_review_data.DI_actions_name.slice(0, -1);
        }

        if (this.selection_review_data.checked_credits.indexOf("base_score") > -1) {
            this.selection_review_data.all_reviewed_actions += ',base_score';
        }
    };

    getProjectScore(score: any) {
        var energy = ((isNaN(parseInt(score.energy)) ? 0 : parseInt(score.energy)));
        var water = ((isNaN(parseInt(score.water)) ? 0 : parseInt(score.water)));
        var waste = ((isNaN(parseInt(score.waste)) ? 0 : parseInt(score.waste)));
        var human_experience = ((isNaN(parseInt(score.human_experience)) ? 0 : parseInt(score.human_experience)));
        var transport = ((isNaN(parseInt(score.transport)) ? 0 : parseInt(score.transport)));
        var base_score = ((isNaN(parseInt(score.base)) ? 0 : parseInt(score.base)) / 10);
        return parseInt((energy + water + waste + human_experience + transport + base_score).toString());
    };

    getProjectScoreCat(score: any) {
        return ((isNaN(parseInt(score)) ? 0 : parseInt(score)))
    };

    submitOnlyReview() {

        this.selection_review_data.only_review_button = "Submitting...";
        this.selection_review_data.only_review_button_loading = false;
        this.canada_payment = false;

        var review = "";

        if (this.selection_review_data.review_type == 'Certification') {
            review = 'review';
        }
        else if (this.selection_review_data.review_type == 'Pre-Certification') {
            review = 'review';
        }
        else if (this.selection_review_data.review_type == 'LEED Certification') {
            review = 'recertification';
            if (this.appData.get('buildingData').country == 'CA' && (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school')) {
                this.canada_payment = true;
                this.area_in_sqm = this.appData.get('buildingData').gross_area;
                if ( this.appData.get('buildingData').gross_area && this.appData.get('buildingData').unitType == 'IP'){
                    this.area_in_sqm = this.appData.get('buildingData').gross_area * 0.092903;
                }
            }
        }

        var selected_date = this.selection_review_data.review_end_date.split('/');
        var new_end_date_obj = new Date(selected_date[2], parseInt(selected_date[0]) - 1, selected_date[1]);
        var new_start_date_obj = new Date(selected_date[2], parseInt(selected_date[0]) - 1, selected_date[1]);
        new_start_date_obj.setFullYear(new_start_date_obj.getFullYear() - 1);

        var review_start_date = String(new_start_date_obj.getFullYear()) + '-' + ('0' + String(new_start_date_obj.getMonth() + 1)).slice(-2) + '-' + ('0' + String(new_start_date_obj.getDate())).slice(-2);

        var review_end_date = String(new_end_date_obj.getFullYear()) + '-' + ('0' + String(new_end_date_obj.getMonth() + 1)).slice(-2) + '-' + ('0' + String(new_end_date_obj.getDate())).slice(-2);

        var payment_payload: any =
        {
            "soreference": review,
            "energy_score": this.getProjectScoreCat(this.selection_review_data.performanceData.energy),
            "water_score": this.getProjectScoreCat(this.selection_review_data.performanceData.water),
            "waste_score": this.getProjectScoreCat(this.selection_review_data.performanceData.waste),
            "base_score": this.getProjectScoreCat(this.selection_review_data.base_attempted),
            "human_score": this.getProjectScoreCat(this.selection_review_data.performanceData.human_experience),
            "transport_score": this.getProjectScoreCat(this.selection_review_data.performanceData.transport),
            "energy_score_100": this.formatNull(this.selection_review_data.score_obj.energy),
            "water_score_100": this.formatNull(this.selection_review_data.score_obj.water),
            "waste_score_100": this.formatNull(this.selection_review_data.score_obj.waste),
            "transport_score_100": this.formatNull(this.selection_review_data.score_obj.transport),
            "human_score_100": this.formatNull(this.selection_review_data.score_obj.human_experience),
            "review_type": this.selection_review_data.review_type,
            "certification_level": this.selection_review_data.cert_level,
            "certification_type": this.selection_review_data.cert_type,
            "review_start_date": review_start_date,
            "review_expiry_date": review_end_date,
            "score_submitted": this.getProjectScore(this.selection_review_data.performanceData),
            "target_score": this.selection_review_data.target_score,
            "target_level": this.selection_review_data.target_level,
            "creditId": this.selection_review_data.all_reviewed_actions,
            "creditDescription": this.selection_review_data.all_actions_name,
            "prereqsCreditDescription": this.selection_review_data.prereqs_actions_name,
            "baseCreditDescription": this.selection_review_data.base_actions_name,
            "dataInputCreditDescription": this.selection_review_data.DI_actions_name,
            "extra_info": (this.selection_review_data.extra_info_text).trim(),
            "point_of_contact": this.selection_review_data.point_of_contact
        }

        var form_data = new FormData();

        for (var key in payment_payload) {
            form_data.append(key, payment_payload[key]);
        }

        var custom_basic_config_header =
        {
            headers:
            {
                "responseType": "arraybuffer",
                "Ocp-Apim-Subscription-Key": this.config.sub_key,
                "Authorization": "Bearer " + this.appData.get('token')
            }
        };

        this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/review/?mode=review_only', form_data, custom_basic_config_header).subscribe(
            data => {
                this.selection_review_data.only_review_button = "Submit for review";
                this.selection_review_data.only_review_button_loading = false;
                this.selection_review_data.review_successfully_submitted = true;
                try {
                    if (this.selection_review_data.review_history.review_payment != undefined) {
                        var review_payment = this.selection_review_data.review_history.review_payment[0];
                        if (review_payment.status == 'completed') {
                            this.selection_review_data.final_payment_status = 'completed';
                        }
                        else{
                            this.selection_review_data.final_payment_status = '';
                        }
                    }
                    else{
                        this.selection_review_data.final_payment_status = '';
                    }
                } catch (error) {
                    this.selection_review_data.final_payment_status = '';
                }

                this.selection_review_data["canada_payment"] = this.canada_payment;
                this.selection_review_data["area_in_sqm"] = this.area_in_sqm;
                this.appData.set('reviewData', this.selection_review_data);
                this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'review-success' })
            }, error => {
                this.selection_review_data.only_review_button = "Submit for review";
                this.selection_review_data.only_review_button_loading = false;
            }
        );
    };

    checkSubmittedCredits(){
        if (this.selection_review_data.checked_credits.length < 5){
            $('#recert_categories_confirmation').modal('show');
        }
        else{
            this.checkLEEDACPprerequisite();
        }
    }

    checkLEEDACPprerequisite(){
        $('#recert_categories_confirmation').modal('hide');
        if (this.appData.get('buildingData').energy_score_calculation_factor != 'default' || this.appData.get('buildingData').water_score_calculation_factor != 'default'){
            this.acp_warning_message = '';
            if (!this.selection_review_data.performanceData.subscores_source && !this.selection_review_data.performanceData.water){
                this.acp_warning_message = 'Project does not meet minimum prerequisite for source energy and water. Are you sure you want to continue?';
            }
            else if (!this.selection_review_data.performanceData.subscores_source){
                this.acp_warning_message = 'Project does not meet minimum prerequisite for source energy. Are you sure you want to continue?';
            }
            else if (!this.selection_review_data.performanceData.water){
                this.acp_warning_message = 'Project does not meet minimum prerequisite for water. Are you sure you want to continue?';
            }
            if (this.acp_warning_message != ''){
                $('#leed_acp_warning').modal('show');
            }
            else{
                this.goToPaymentOrSubmit();
            }
        }
        else{
            this.goToPaymentOrSubmit();
        }
    }

    // go to payment
    goToPaymentOrSubmit() {
        $('#recert_categories_confirmation').modal('hide');
        $('#leed_acp_warning').modal('hide');
        if (this.selection_review_data.only_review) {
            this.submitOnlyReview();
        }
        else {
            this.appData.set('reviewData', this.selection_review_data);
            this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'review-payment' })
        }
    };

    // go to review payment from modal window
    goToReviewPayment_modal() {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.SummaryCheck();
        this.goToPaymentOrSubmit();
    };

    // go to review payment
    goToReviewPayment() {

        var doc_count;
        var credit_status;
        var flag = true;
        var i;

        if ((this.base_status == 'Ready for Review' || this.base_score_city_files.length > 0) && (this.selection_review_data.review_type == 'Certification' || this.selection_review_data.review_type == 'Pre-Certification') && !this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
            if (this.selection_review_data.checked_credits.indexOf("base_score") != -1) {
                flag = true;
            }
            else {
                flag = false;
                this.alert_of.push("Base Score");
            }
        }

        for (i = 0; i < this.selection_review_data.complete_all_actions.length; i++) {
            if (this.selection_review_data.complete_all_actions[i].Mandatory != "X") {
                if (this.only_PF(this.selection_review_data.complete_all_actions[i])) {
                    continue;
                }
                if (this.only_base(this.selection_review_data.complete_all_actions[i])) {
                    if ((!(this.selection_review_data.review_type == 'Certification' || this.selection_review_data.review_type == 'Pre-Certification')) || this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                        continue;
                    }
                }
                doc_count = this.selection_review_data.complete_all_actions[i].document_count;
                credit_status = this.selection_review_data.complete_all_actions[i].CreditStatus;
                if (doc_count > 0 || credit_status == 'Ready for Review') {
                    if (this.selection_review_data.checked_credits.indexOf(this.selection_review_data.complete_all_actions[i].CreditShortId) != -1) {
                        flag = true;
                    }
                    else {
                        flag = false;
                        this.alert_of.push(this.selection_review_data.complete_all_actions[i].CreditDescription);
                    }
                }
            }
        }

        if (flag && Object.keys(this.alert_of).length == 0) {
            this.SummaryCheck();
            if (this.isBuildingRecert){
                this.checkSubmittedCredits();
            }
            else{
                this.goToPaymentOrSubmit();
            }
        }
        else {
            $('.credit_doc_notification').modal('toggle');
        }
    };

    deleteFileAdditionalFile(uploaded_file: any, elem: any) {
        elem.showThisLoader = true;
        var putData =
        {
            doc_id: uploaded_file.id
        };

        this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
            data => {
                elem.showThisLoader = false;
                this.global.getUploadedFiles('additional_file');
            }, error => {
                elem.showThisLoader = false;
            }
        );
    };

    goToManageBilling() {
        this.appData.set('reviewData', {});
        this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'billing' })
    };

    getIconFromScore(score: any) {

        if (40 <= score && score <= 49) {
            return "../../../assets/images/logo/LEED_Certified.svg";
        } else if (50 <= score && score <= 59) {
            return "../../../assets/images/logo/LEED_Silver.svg";
        } else if (60 <= score && score <= 79) {
            return "../../../assets/images/logo/LEED_Gold.svg";
        } else if (80 <= score) {
            return "../../../assets/images/logo/LEED_Platinum.svg";
        }
        else {
            return "../../../assets/images/logo/Non_LEED.svg";
        }
    };

    certGuideRedirect() {
        if (this.appData.get('buildingData').project_type == 'building' || this.appData.get('buildingData').project_type == 'school') {
            window.open("https://new.usgbc.org/leed-v41#recertification", "_blank");
        }
        else if (this.appData.get('buildingData').project_type == 'transit') {
            window.open("https://www.usgbc.org/resources/leed-v4-operations-and-maintenance-transit-guidance", "_blank");
        }
        else if (this.appData.get('buildingData').project_type == 'city') {
            window.open("https://new.usgbc.org/cert-guide/cities-communities", "_blank");
        }
        else if (this.appData.get('buildingData').project_type == 'community') {
            window.open("https://new.usgbc.org/cert-guide/cities-communities", "_blank");
        }
    };

    getCreditName(credit: any) {
        if (credit.CreditShortId.toLowerCase() == 'pf901') {
            return "Energy";
        }
        else if (credit.CreditShortId.toLowerCase() == 'pf902') {
            return "Water";
        }
        else if (credit.CreditShortId.toLowerCase() == 'pf903') {
            return "Waste";
        }
        else if (credit.CreditShortId.toLowerCase() == 'pf904') {
            return "Transportation";
        }
        else if (credit.CreditShortId.toLowerCase() == 'pf905') {
            return "Human Experience";
        }
        else if (credit.CreditShortId.toLowerCase() == 'pf906') {
            return "Additional Data";
        }
    };

    formatNumber(number: any, decimals: any) {
        if (isNaN(number)) {
            number = 0
        }

        var amt: any = parseFloat(number);
        amt = amt.toFixed(decimals);
        if (isNaN(amt) || String(amt) == 'Infinity' || String(amt) == '-Infinity') {
            return '0';
        }
        else if (number == 0) {
            return 0;
        }
        else {
            return amt;
        }
    };

    formatToInt(val: any) {
        if (Number.isNaN(val)) {
            return 0;
        }
        else {
            return parseInt(val);
        }
    };

    toCamel(str: string)
	{
		return str.replace(/\w\S*/g, function(txt)
		{
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

    getTeam()
	{
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') +'/teams/'
		).subscribe(
			//success handler
			(data: any) =>
			{
				this.team_members = [];
				for (var i = 0; i < data.EtTeamMembers.length; i++)
				{
					if (data.EtTeamMembers[i].Responsibility != 'D' && (data.EtTeamMembers[i].Roleid == "ZRPO80" || data.EtTeamMembers[i].Roleid == "ZRPO81" || data.EtTeamMembers[i].Roleid == "ZRPO82"))
					{
						this.team_members.push(data.EtTeamMembers[i]);
					}
				}
			}
		);
	};

    refreshSelectedDI(){
        this.selection_review_data.target_score = this.selection_review_data.target_score_base;
        for (let index = 0; index < this.selection_review_data.checked_credits.length; index++) {
            var credit_val = this.selection_review_data.checked_credits[index];
            if (credit_val == 'PF901'){
                this.selection_review_data.target_score += this.selection_review_data.performanceData.energy;
            }
            else if (credit_val == 'PF902'){
                this.selection_review_data.target_score += this.selection_review_data.performanceData.water;
            }
            else if (credit_val == 'PF903'){
                this.selection_review_data.target_score += this.selection_review_data.performanceData.waste;
            }
            else if (credit_val == 'PF904'){
                this.selection_review_data.target_score += this.selection_review_data.performanceData.transport;
            }
            else if (credit_val == 'PF905'){
                this.selection_review_data.target_score += this.selection_review_data.performanceData.human_experience;
            }
        }
    };

    getCerRegistrationDetails(){
        this.registration_loading = true;
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/registration/').subscribe(
            data => {
                this.registration_loading = false;
                if (data.length > 0 && data[0].active) {
                    this.registration_status = 'registered';
                    this.selection_review_data.cert_rating_system = data[0].rating_system;
                    if (this.selection_review_data.cert_rating_system == 'v4 O+M: Existing Buildings recertification' || this.selection_review_data.cert_rating_system == 'v4 O+M: Interiors recertification'){
                        this.DI_heading_name = 'Select performance categories for review';
                    }
                    else{
                        this.selection_review_data.checked_credits = ['PF901', 'PF902', 'PF903', 'PF904', 'PF905'];
                    }
                }
                else{
                    this.registration_status = 'not_registered';
                    $('.registration_not_found').modal('show');
                }
            },
            error => {
                this.registration_loading = false;
                this.registration_status = 'not_registered';
                $('.registration_not_found').modal('show');
            }
        );
    };

    ngOnInit(): void {
        this.selection_review_data = this.appData.get('reviewData');
        if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community') {
            this.projects.getS3DocumentsForCategory("base_score").subscribe(data => {
                this.base_score_city_files = data;
            });

            this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/measuresversion/base_score/').subscribe(
                data => {
                    var status = data.results[0].status;
                    this.base_status = status;
                },
                error => {
                }
            );
        }

        if (this.selection_review_data.review_type == undefined) {
            //Right side data

            this.selection_review_data = {
                "need_review_type_drp": false,
                "review_types": [],
                "base_attempted": 0,
                "target_score": 0,
                "current_score": 0,
                "target_level": "",
                "all_reviewed_actions": "",
                "all_actions_name": "",
                "prereqs_actions_name": "",
                "base_actions_name": "",
                "DI_actions_name": "",
                "checked_credits": [],
                "only_review": false,
                "review_history": {},
                "payment_details": {},
                "review_end_date": '',
                "last_selected_period": ('0' + String((new Date()).getMonth() + 1)).slice(-2) + '/' + ('0' + String((new Date()).getDate())).slice(-2) + '/' + String((new Date()).getFullYear()),
                "extra_info_text": "",
                "review_successfully_submitted": false,
                "only_review_button": "Submit for review",
                "only_review_button_loading": false,
                "cert_rating_system": "",
                "selected_review_end_date": false,
                "date_range": "",
                "project_settings": "",
                "performanceData": {},
                "score_obj": {},
                "maxima": {},
                "all_actions_filter_X": [],
                "all_actions_filter_only_base": [],
                "all_actions_filter_only_pf": [],
                "point_of_contact": "",
                "complete_all_actions": []
            }

            this.api.get('/country/states/').subscribe(
                data => {
                    var country_perf_title = this.global.getCountryName(this.appData.get('buildingData').country, this.global.doSorting(data.countries));
                    var state_perf_title = this.global.getStateName(this.appData.get('buildingData').state, this.global.doSorting(data.divisions[this.appData.get('buildingData').country]));

                    if (state_perf_title == undefined || state_perf_title == "" || state_perf_title == null) {
                        this.selection_review_data.state_country = country_perf_title;
                    }
                    else {
                        this.selection_review_data.state_country = state_perf_title + ', ' + country_perf_title;
                    }
                },
                error => {
                }
            );

            //Performance date starts
            
            var nowTemp = new Date();
            nowTemp.setDate(nowTemp.getDate() - 1);
            (<any>$('#end_review_date')).fdatepicker({
                onRender: function (date: any) {
                    return date.valueOf() > nowTemp.valueOf() ? 'disabled' : '';
                }
            }).on('changeDate', (ev: any) =>
            {
                this.selection_review_data.review_end_date = $('#end_review_date').val();
                if (this.selection_review_data.review_end_date != ''){
                    this.changePerformancePeriod(false);
                }
            });

            //Performance date ends

            if (!this.global.checkInCurrentRatingSystem()) {
                if (this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                    if (this.appData.get('buildingData').rating_system.toLowerCase() == 'none' || this.appData.get('buildingData').rating_system.toLowerCase() == 'other') {
                        if (this.global.isNoneApplicableForRecert()) {
                            this.selection_review_data.cert_type = "LEED Certification";
                            this.selection_review_data.review_types = ["LEED Certification"];
                            this.selection_review_data.checked_credits = ['PF901', 'PF902'];
                        }
                    }
                    else {
                        this.selection_review_data.cert_type = "LEED Certification";
                        this.selection_review_data.review_types = ["LEED Certification"];
                        this.selection_review_data.checked_credits = ['PF901', 'PF902'];
                    }
                }
            }
            else {
                if (this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                    this.selection_review_data.cert_type = "LEED Certification";
                    this.selection_review_data.review_types = ["LEED Certification"];
                    this.selection_review_data.checked_credits = ['PF901', 'PF902'];
                }
                else {
                    if (this.appData.get('buildingData').project_type.toLowerCase() == "building" || this.appData.get('buildingData').project_type.toLowerCase() == "school") {
                        this.selection_review_data.cert_type = "LEED V4 O+M: EB";
                    }
                    else if (this.appData.get('buildingData').project_type.toLowerCase() == "city") {
                        this.selection_review_data.cert_type = "LEED for Cities";
                    }
                    else if (this.appData.get('buildingData').project_type.toLowerCase() == "community") {
                        this.selection_review_data.cert_type = "LEED for Communities";
                    }
                    else if (this.appData.get('buildingData').project_type.toLowerCase() == "transit") {
                        this.selection_review_data.cert_type = "LEED V4 O+M: TR";
                    }

                    if (this.global.isPreCertified(this.appData.get('buildingData').certification)) {
                        this.selection_review_data.review_types = ["Certification"];
                    }
                    else {
                        this.selection_review_data.review_types = ["Certification", "Pre-Certification"];
                        this.selection_review_data.need_review_type_drp = true;
                    }
                }
            }
            this.selection_review_data.review_type = this.selection_review_data.review_types[0];

            this.api.async([
                '/assets/LEED:' + this.appData.get('leed_id') + '/actions/?document=true',
                '/assets/LEED:' + this.appData.get('leed_id') + '/scores/v2/',
                '/assets/LEED:' + this.appData.get('leed_id') + '/payments/order/',
                '/assets/LEED:' + this.appData.get('leed_id') + '/review/',
                '/assets/LEED:' + this.appData.get('leed_id') + '/',
                '/assets/LEED:' + this.appData.get('leed_id') + '/datacoverage/',
            ]).subscribe(data => {
                var actions = data[0].EtScorecardList;
                var score = data[1];
                var payment_details = data[2];
                var review_history = data[3];
                var score_count = 0;
                var total_base_points = 0;
                var datacoverage = data[5];

                this.selection_review_data.performanceData = score.scores;
                this.selection_review_data.score_obj = score;
                this.selection_review_data.maxima = score.maxima;
                this.selection_review_data.review_history = review_history;
                this.selection_review_data.payment_details = payment_details;
                this.appData.set('buildingData', data[4]);

                if (this.selection_review_data.performanceData == undefined) {
                    this.selection_review_data.performanceData = { "energy": 0, "water": 0, "waste": 0, "human_experience": 0, "transport": 0, "base": 0 };
                }

                for (var i = 0; i < actions.length; i++) {
                    if (actions[i].CreditShortId == 'PF901') {
                        actions[i]["score"] = this.selection_review_data.performanceData["energy"];
                        actions[i]["maxima"] = this.selection_review_data.maxima["energy"];
                        actions[i]["datacoverage"] = datacoverage["energy"];
                        actions[i]["document_count"] = datacoverage["energy"]["documents"];
                    }
                    else if (actions[i].CreditShortId == 'PF902') {
                        actions[i]["score"] = this.selection_review_data.performanceData["water"];
                        actions[i]["maxima"] = this.selection_review_data.maxima["water"];
                        actions[i]["datacoverage"] = datacoverage["water"];
                        actions[i]["document_count"] = datacoverage["water"]["documents"];
                    }
                    else if (actions[i].CreditShortId == 'PF903') {
                        actions[i]["score"] = this.selection_review_data.performanceData["waste"];
                        actions[i]["maxima"] = this.selection_review_data.maxima["waste"];
                        actions[i]["datacoverage"] = datacoverage["waste"];
                        actions[i]["document_count"] = datacoverage["waste"]["documents"];
                    }
                    else if (actions[i].CreditShortId == 'PF904') {
                        actions[i]["score"] = this.selection_review_data.performanceData["transport"];
                        actions[i]["maxima"] = this.selection_review_data.maxima["transport"];
                        actions[i]["datacoverage"] = datacoverage["transportation"];
                        actions[i]["document_count"] = datacoverage["transportation"]["documents"];
                    }
                    else if (actions[i].CreditShortId == 'PF905') {
                        actions[i]["score"] = this.selection_review_data.performanceData["human_experience"];
                        actions[i]["maxima"] = this.selection_review_data.maxima["human_experience"];
                        actions[i]["datacoverage"] = datacoverage["humanexperience"];
                        actions[i]["document_count"] = datacoverage["humanexperience"]["documents"];
                    }
                }

                if (this.appData.get('buildingData').base_score) {
                    total_base_points = parseInt((Math.round(this.appData.get('buildingData').base_score / 10)).toString());
                }

                if (total_base_points >= 10) {
                    total_base_points = 10;
                }

                score_count = total_base_points;
                if (score.scores) {
                    score_count += score.scores.energy + score.scores.water + score.scores.waste + score.scores.transport + score.scores.human_experience;
                }
                
                this.selection_review_data.target_score = score_count;
                this.selection_review_data.current_score = score_count;
                this.selection_review_data.target_score_obj = score.scores;
                this.selection_review_data.target_score_base = total_base_points;

                if (40 <= score_count && score_count <= 49) {
                    this.selection_review_data.target_level = "Certified";
                } else if (50 <= score_count && score_count <= 59) {
                    this.selection_review_data.target_level = "Silver";
                } else if (60 <= score_count && score_count <= 79) {
                    this.selection_review_data.target_level = "Gold";
                } else if (80 <= score_count) {
                    this.selection_review_data.target_level = "Platinum";
                }
                else {
                    this.selection_review_data.target_level = "Not applicable";
                }

                this.selection_review_data.cert_level = this.selection_review_data.target_level;

                this.selection_review_data.complete_all_actions = actions;

                for (var i = 0; i < this.selection_review_data.complete_all_actions.length; i++) {
                    if (this.selection_review_data.complete_all_actions[i].Mandatory == 'X') {
                        this.selection_review_data.all_actions_filter_X.push(this.selection_review_data.complete_all_actions[i]);
                    }
                    if ((this.selection_review_data.complete_all_actions[i].Mandatory != 'X') && (this.selection_review_data.complete_all_actions[i].CreditcategoryDescrption != 'Performance Category') && (this.selection_review_data.complete_all_actions[i].CreditcategoryDescrption != 'Performance')) {
                        this.selection_review_data.all_actions_filter_only_base.push(this.selection_review_data.complete_all_actions[i]);
                    }
                    if ((this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF901') || (this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF902') || (this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF903') || (this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF904') || (this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF905') || (this.selection_review_data.complete_all_actions[i].CreditShortId == 'PF906')) {
                        this.selection_review_data.all_actions_filter_only_pf.push(this.selection_review_data.complete_all_actions[i]);
                    }
                }
                this.selection_review_data.all_actions_filter_only_pf.sort(this.global.sortList('CreditShortId'));

                this.validationForContinue();
                this.IsReviewOnly();
                this.recompute_loading = false;
                this.IsReviewAllowed();
                this.validateRedirectLink();
                this.refreshSelectedDI();
            }, error => {
                this.selection_review_data.ready_for_review = false;
                this.recompute_loading = false;
                if (error) {
                    this.alertService.alert('error', error[0], 5);
                }
                else {
                    this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
                }
            });
        }
        else {
            var checked_arr = this.selection_review_data.checked_credits;
            for (var i = 0; i < checked_arr.length; i++) {
                $('#checkbox-' + (checked_arr[i]).replace('.', '-')).prop('checked', true);
            }
            var nowTemp = new Date();
            nowTemp.setDate(nowTemp.getDate() - 1);
            (<any>$('#end_review_date')).fdatepicker({
                initialDate: this.selection_review_data.review_end_date,
                format: 'mm/dd/yyyy',
                onRender: function (date: any) {
                    return date.valueOf() > nowTemp.valueOf() ? 'disabled' : '';
                }
            }).on('changeDate', (ev: any) =>
            {
                this.selection_review_data.review_end_date = $('#end_review_date').val();
                if (this.selection_review_data.review_end_date != ''){
                    this.changePerformancePeriod(false);
                }
            });
            this.recompute_loading = false;
            this.selection_review_data.only_review_button = "Submit for review";
            this.selection_review_data.only_review_button_loading = false;
            this.IsReviewAllowed();
            this.validateRedirectLink();
        }
        this.global.getUploadedFiles('additional_file');
        this.getCertificationName();
        (<any>document.getElementById('review_scores_chart')).src = this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/overview/arcaveragescore/?access-token=" + this.appData.get('token') + "&subscription-key=" + this.config.sub_key + "&type=total&exclude=reentry_score&no_average=true";
        this.getTeam();
    }

}
