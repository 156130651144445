import { Component, OnInit } from '@angular/core';
import { Config } from '../../models/env-vars.model';
import { API } from 'src/app/services/api.service';
import { AppData } from 'src/app/services/appdata.service';
import { Global } from 'src/app/services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
// import { data } from 'jquery';

@Component({
  selector: 'app-admin-team',
  templateUrl: './admin_team.component.html',
  styleUrls: ['./admin_team.component.css']
})
export class AdminTeamComponent implements OnInit {

  role: string = 'ARC Project Admin';
  role_id: string = 'ZRPO80';
  loader: boolean = false;
  delete_loader: boolean = false;
  delete_leed_id: any = ""; 
  team_members: any = [];

  pf_role: string = 'Can Read';
  pf_role_id: string = 'can_read';

  leed_id: string = ""; 
  user_id: string = "";
  pf_id: string = "";
  pf_user_id: string = "";

  search_results: any = [];
  projects: any = [];
  is_search: boolean = false;
  canceller_search_user: any = undefined;
  search_user_status: boolean = false;
  search_user: any = null;
  selected_user: any = {};
  searching_user: boolean = false;
  canceler: any;
  checkInput: boolean = false;
  
  constructor (
    public global: Global,
		private api: API,
    private alertService: alertService,
		public appData: AppData
  ){}
  
  checkInputs(){
  
   if(isNaN(this.delete_leed_id))
   {
      this.checkInput = false;
   }
   else{
    this.checkInput = true;
   }
  }

  clearList()
  {
    this.search_user = '';
  }
  
  changeRole(role: string, role_id: string)
  {
      this.role = role;
      this.role_id = role_id;
  }

  refreshForms(): void
  {
      this.role = 'ARC Project Admin';
      this.role_id = 'ZRPO80';
      this.user_id = "";
      this.leed_id = "";
      
      this.pf_role = 'Can Read';
      this.pf_role_id = 'can_read';
      this.pf_id = "";
      this.pf_user_id = "";
  };

  changeRolee(title: string, type: string)
  {
      this.role = title;
      this.role_id = type;
  };

  saveRole(): void
  {
    this.loader = true;
    var data = 
    {
        "user_email": this.user_id,
        "Reltyp": this.role_id
    }; 

    this.api.post('/assets/LEED:'+ this.leed_id +'/teams/', data ).subscribe(
      data => {
        this.loader = false;
        this.refreshForms();
        this.alertService.alert('success', 'Team member added successfully.', 5);
      }, error => {
        this.loader = false;
        try
        {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch(e)
        {
            try
            {
                if("detail" in error.error)
                {
                  this.alertService.alert('error', error.error.detail, 5);
                }
            }
            catch(e)
            {
              this.alertService.alert('error', 'Something went wrong', 5);
            }
        }
      }
    );
  };

  getTeam()
  {
    this.team_members = [];
    this.delete_loader = true;

    this.api.get('/assets/LEED:'+ this.delete_leed_id + '/teams/').subscribe(
      data => {
        this.team_members = data.EtTeamMembers;
        this.delete_loader = false;
      },
      error => {
        this.delete_loader = false;
        try
        {
          this.alertService.alert('error', error.error[0].message, 5);
        }
        catch(e)
        {
            try
            {
                if("detail" in error.error)
                {
                  this.alertService.alert('error', error.error.detail, 5);
                }
            }
            catch(e)
            {
              this.alertService.alert('error', 'Something went wrong', 5);
            }
        }
      }
    );
  };

  removeUser(team_role: any)
	{
		var acr_admin_email = "";
		var acr_admin_count = 0;
		for (var i = 0; i < this.team_members.length; i++)
		{
			if (this.team_members[i].Roleid == "ZRPO80" && this.team_members[i].Responsibility != "D")
			{
				acr_admin_count += 1;
				acr_admin_email = this.team_members[i].email;
			}
		}

		if ((acr_admin_count == 1) && acr_admin_email == team_role.Useralias.trim())
		{
			this.alertService.alert('error', 'Project should have at least one Arc Administrator.', 5);
			return;
		}

    var delete_data = 
    {
      "user_email": team_role.Useralias,
      "Reltyp": team_role.Roleid,
      "Responsibility": String(this.delete_leed_id)
    }
    this.delete_loader = true;

    this.api.delete('/assets/LEED:' + this.delete_leed_id + '/teams/update/', delete_data ).subscribe(
      data => {
        this.delete_loader = false;
        this.getTeam();
        this.alertService.alert('success', 'Team member removed successfully.', 5);
      },
      error => {
        this.delete_loader = false;
        if (error.error[0].Type== "E")
        {
            this.alertService.alert('error', error.error[0].message, 5);
        }
        else
        {
            this.alertService.alert('error', 'Please try again.', 5);
        }
      }
    );
  };

  changeRolePF(role: any, role_id: any)
  {
      this.pf_role = role;
      this.pf_role_id = role_id;
  };

  saveRolePF()
  {
    this.loader = true;
    var data = 
    {
        "username": this.pf_user_id,
        "permission": this.pf_role_id
    };

    this.api.post('/portfolios/ID:' + this.pf_id + '/teams/', data ).subscribe(
      data => {
        this.loader = false;
        this.refreshForms();
        this.alertService.alert('success', 'Team member added successfully.', 5);
      }, error => {
        this.loader = false;
        try
        {
            if("detail" in error.error)
            {
                this.alertService.alert('error', error.error.detail, 5);
            }
        }
        catch(e)
        {
            this.alertService.alert('error', 'Something went wrong', 5);
        }
      }
    );
  };

  // search user

  isSearch(is_search: boolean) {
    this.is_search = is_search;
  };

  getUser(user: any, query: any) {
    var search = String(user.name) + " (" + user.email + ")";
    var replace = "<span class='fw-500'>" + query + "</span>";
    var regEx = new RegExp(query, "ig");
    return search.replace(regEx, replace);
  };

  exportData() {
    this.loader = true;
    var url = Config.basic_api_url + '/optask/teamsproject/?searchkey=' + this.selected_user.email + '&export=True';
    var xhttp: any = new XMLHttpRequest();
		xhttp.onreadystatechange = () =>
		{
			var a, today;
			if (xhttp.readyState === 4 && xhttp.status === 200)
			{
				if((<any>navigator).msSaveOrOpenBlob)
				{
					(<any>navigator).msSaveOrOpenBlob(xhttp.data, "Team Export.xlsx");
					this.loader = false;
				}
				else
				{
					this.loader = false;
					a = document.createElement('a');
					a.download = "Team Export.xlsx";
					a.style.display = 'none';
					a.href = window.URL.createObjectURL(xhttp.response);
					document.body.appendChild(a);
					return a.click();
				}
			}
		};
		xhttp.open("GET", url, true);
		xhttp.setRequestHeader("Content-Type", "application/json");
		xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", Config.sub_key);
		xhttp.setRequestHeader("Authorization", "Bearer " + this.appData.get('token'));
		xhttp.responseType = 'blob';
		xhttp.send();
  };

  getProjects() {
    this.loader = true;
    this.api.get('/optask/teamsproject/?searchkey=' + this.selected_user.email).subscribe(
      data => {
        this.projects = data;
        this.loader = false;
      },
      error => {
        this.loader = false;
        this.alertService.alert('error', 'Something went wrong.', 5);
      }
    );
  };

  searchUser(search_user: any) {
    if(this.canceler)
		{
			this.canceler.unsubscribe();
		}
    if (search_user.length == 0) {
      this.search_results = [];
      return;
    }
    this.searching_user = true;
    this.canceler = this.api.get("/auth/user/search/?search=" + search_user).subscribe(
      data => {
        this.searching_user = false;
        this.search_results = data.results;
      },
      error => {
        this.searching_user = false;
      }
    );
  };

  getStatus(status: any) {
    if (status == undefined)
      return ''
    if (status == "agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "addendum_agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "activated_agreement_pending") {//use
      return "Sign agreement"
    }
    else if (status == "activated_addendum_agreement_pending") {
      return "Sign agreement"
    }
    else if (status == "activated_payment_pending") { //use, Activate now-->Reg form with popu;ate fields
      return "Make payment"
    }
    else if (status == "activated_payment_done") { //use

      return "Registered"
    }
    else if (status == "activated_under_review") {

      return "Under Review"
    }
    else if (status == "activated_review_payment_pending") {

      return "Deactivated"
    }
    else {
      return status.replace(/\_/g, ' ').capitalize();
    }
  };

  selectUser(user: any) {
    this.selected_user = user;
    this.isSearch(false);
    this.getProjects();
  };

  ngOnInit(): void {    
    // this.loader = false;
    $('body').click((e) => {
      if (e.target.classList.contains('arc-pro-search') || e.target.classList.contains('user-item')) {
        this.isSearch(true);
      }
      else {
        this.isSearch(false);
      }
    });
  
  }

}
