import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { Global } from '../../../services/global.service';
import { API } from 'src/app/services/api.service';
import { alertService } from 'src/app/services/alert-service.service';

import { Config } from 'src/app/models/env-vars.model';

@Component({
  selector: 'app-multiple-team-manage',
  templateUrl: './multiple-team-manage.component.html',
  styleUrls: ['./multiple-team-manage.component.css']
})
export class MultipleTeamManageComponent implements OnInit
{
	config = Config;
	@Output() close = new EventEmitter<string>();
	
	selected_user: any = [];
	selected_projets: any = [];
	search_results: any = [];
	selected_users: any = [];

	operation: any = null;
	user_role: any = 'ZRPO81';
	mode: string= 'user_email';
    canceller_search_user: any = undefined;
    search_user_status: boolean = false;
    loading: boolean = false;
    search_user: any = [];
    is_search: boolean = false;
    current_tab: string = '1. Select users';
    visited: string[] = ['1. Select users'];
    tabs: string[] = ['1. Select users', '2. Add/Remove user', '3. Select projects', '4. Summary'];

	constructor(
		public global: Global,
		private api: API,
		private alertService: alertService,
		public appData: AppData
	)
	{

	}

	ngOnInit(): void
	{

	}

	getRole(role_id: string)
	{
		if(role_id == 'ZRPO80')
        {
            return 'an Arc Administrator';
        }
        else if(role_id == 'ZRPO81')
        {
            return 'a Team Member';
        }
        else if(role_id == 'ZRPO82')
        {
            return 'a Team Manager';
        }
	}

	getUser(user: any, query: string)
    {
        var search = String(user.name) + " ("+ user.email +")";
        try
        {
            var replace = "<span class='fw-500'>"+ query +"</span>";
            var regEx = new RegExp(query, "ig");
            return search.replace(regEx, replace);
        }
        catch(e)
        {
            return "<span'>"+ search +"</span>";
        }
    };

    isSearch(is_search: boolean)
    {
        this.is_search = is_search;
    };

	searchUser(search_user: string)
    {
        this.api.get("/auth/user/search/?search=" + encodeURIComponent(search_user)).subscribe(data =>
		{
			this.search_results = data.results;
		});
    };

	selectedUser(email: string)
    {
        var found = false;
        for(var n in this.selected_users)
        {
            if(this.selected_users[n].email == email)
            {
                found = true;
                break;
            }
        }
        return found;
    };

    getInitials(string: string)
    {
        var names = string.split(' '), initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1)
        {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    addUser(user: any)
    {
        var found = false;
        for(var i = 0; i < this.selected_user.length; i++)
        {
            if(this.selected_user[i].email == user.email)
            {
                found = true;
                break;
            }
        }
        if(found)
        {
            return;
        }
        this.selected_user.push({
            "name": user.name,
            "email": user.email,
        });
        this.isSearch(false);
    };

    removeUser(idx: number)
    {
        this.selected_user.splice(idx, 1);
    };

    cancel()
    {
		this.close.emit('close');
    };

    toggleTab(tab: string)
    {
        // this.user_role = $('#user_role').val();
        if(tab == this.current_tab)
        {
            return;
        }
        else if(tab == '1. Select users')
        {
            this.current_tab = tab;
        }
        else if(tab == '2. Add/Remove user')
        {
            if(this.selected_user.length == 0)
            {
                return;
            }
            this.visited.push(tab);
            this.current_tab = tab;
        }
        else if(tab == '3. Select projects')
        {
            if(this.operation == null)
            {
                return;
            }
            this.visited.push(tab);
            this.current_tab = tab;
            // this.resetFilters(true);
            // this.initFilter();
        }
        else if(tab == '4. Summary')
        {
            if(this.selected_projets.length == 0)
            {
                return;
            }
            this.visited.push(tab);
            this.current_tab = tab;
        }
    };

    toggleType(operation: any)
    {
        this.operation = operation;
    }

    goBack()
    {
        var idx = this.tabs.indexOf(this.current_tab);
        if(idx > 0)
        {
            this.toggleTab(this.tabs[idx - 1]);
        }
    }

	getSelectedProjects(data: any)
	{
		this.selected_projets = data;
	}
    
    continueTeam()
    {
        if(this.current_tab == '1. Select users')
        {
            this.toggleTab('2. Add/Remove user');
        }
        else if(this.current_tab == '2. Add/Remove user')
        {
            this.toggleTab('3. Select projects');
        }
        else if(this.current_tab == '3. Select projects')
        {
            this.toggleTab('4. Summary');
        }
    };

    saveTeam()
    {
        var users = [];
        for(var i = 0; i < this.selected_user.length; i++)
        {
            users.push(this.selected_user[i].email);
        }
        if(this.operation)
        {
            var payload = {
                "roleid": this.user_role,
                "user_email": users,
                "leed_ids": this.selected_projets
            };
            this.loading = true;
            this.api.post('/projects/teams/', payload).subscribe(
                response =>
                {
                    this.loading = false;
                    this.alertService.alert('success', 'Team member(s) added successfully.', 5);
                    this.cancel();
                },
                error =>
                {
                    this.loading = false;
                    this.alertService.alert('error', 'Error adding team member.', 5);
                }
            );
        }
        else if(this.operation == false)
        {
            let payload: any = {
                "user_email": users,
                "leed_ids": this.selected_projets
            };
            this.loading = true;
            this.api.delete('/projects/teams/', payload).subscribe(
                response =>
                {
                    this.loading = false;
                    this.alertService.alert('success', 'Team member(s) removed successfully.', 5);
                    this.cancel();
                },
                error =>
                {
                    this.loading = false;
                    this.alertService.alert('error', 'Error removing team member.', 5);
                }
            );
        }
    };

}
