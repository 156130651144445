export const environment =
{
	production: true,
	ENV_FLAG: "PRD",

	subscription_key: '96ccf4f5ee6d43f1ad0cfcdcbb27a7af',
    basic_api_url   : 'https://api.usgbc.org/arc',
    rating_systems  :  {
		building: 'LEED V4 O+M: EB WP',
		city: 'LEED-CT',
		community: 'LEED-CM',
		district: 'LEED-DT',
		neighborhood: 'LEED-NB',
		transit: 'LEED V4 O+M: TR',
		parksmart: 'PARKSMART',
		sites: 'SITES'
	},
    logout_url      : 'https://arc.gbci.org/arc-user-logout',
    logout_url_jp   : 'https://arcjapan.jp/',
    LO_URL          : 'https://www.leedonline.com/',
    LOV2_URL        : 'https://leedonline.usgbc.org/Login.aspx',
    animation_url   : 'api-v2.arconline.io/',
    FRONTEND_BASE_URL: "https://api.usgbc.org/arc",
    DEMO_FLAG       : false,
    LO_PROXY_URL    : "https://leedonline-fileproxy.usgbc.org",
    landingPageURL   : 'https://arc.gbci.org/',
    usgbc_basic_url  : "https://www.usgbc.org/",
    insight_url      : "https://api.usgbc.org/skanska",
    v1_applicable_date: '2019-11-16',
    it_subscription_key: '308bb12d9f4c4bf5a337cf9f4e7dc2a2',
    all_arc_material: [4958, 5161, 5352, 5353, 5354, 5355, 5388, 5389, 5671, 5672, 5673, 5674, 5675, 5676, 5677, 5678, 5679, 5681, 5682, 5683, 5684, 6100, 6101, 6180, 6181, 6182, 6183, 6246, 6280, 6300, 5680, 6025, 6118, 6119, 6024, 6110, 6114, 5311, 5310, 6113, 6112, 6116, 6115, 4959, 4956, 4957, 5941, 5920, 5162],
    s3_bucket_url: 'https://app.arconline.io',
    sso_client: "x3vLnijUWIUAcR5dgn3KHVkp0EBySosg",
    auth0_url: "https://login.usgbc.org/",
	auth0_domain: "login.usgbc.org",
    accounts_page: "https://accounts.usgbc.org/",
    HUB_URL: "https://www.gbcionline.com/home"
}
